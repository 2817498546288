import {
  Button,
  ContentArea,
  Platform,
  Stack,
  Text,
  showModal,
  usePromisedValue
} from "@gigsmart/atorasu";
import { FeaturesDebugFeature } from "@gigsmart/feature-flags";
import { SecretFeatureButton } from "@gigsmart/isomorphic-shared/feature";
import { getRemoteUrl, remoteLoggingEnabled } from "@gigsmart/roga";
import Clipboard from "@react-native-clipboard/clipboard";
import * as Updates from "expo-updates";
import { isNil } from "lodash";
import React, { useCallback } from "react";
import { getBuildNumber, getVersion } from "react-native-device-info";

const packageBuildNumber = process.env.PACKAGE_BUILD_NUMBER ?? "unknown";
const isHermes = !isNil((global as any).HermesInternal);

export default function BuildInfo() {
  const handleUpdate = useCallback(async () => {
    if (Platform.OS === "web") return;
    const update = await Updates.checkForUpdateAsync();

    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      await Updates.reloadAsync();
    }
  }, []);

  const currentYear = new Date().getFullYear();
  const isNative = Platform.OS !== "web";

  const updateLabel = Updates.updateId;
  const updateStatus = usePromisedValue(Updates.checkForUpdateAsync as any, {
    isAvailable: true
  });
  const bundleUpToDate = updateStatus.isAvailable;
  const remoteUrl = getRemoteUrl();
  const showOssDisclaimer = useCallback(async () => {
    const ossDisclaimer = await fetch("/oss-disclaimer.json")
      .then((res) => res.json())
      .catch(() => "No Open Source Attribution found");
    const lines = ossDisclaimer.split("\r\n|\r|\n");

    showModal({
      eventContext: "Open Source Attribution",
      title: "Open Source Attribution",
      scrollable: true,
      fixedHeight: true,
      children: (
        <ContentArea>
          <Text numberOfLines={lines}>{ossDisclaimer}</Text>
        </ContentArea>
      )
    });
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center">
      <SecretFeatureButton flag={FeaturesDebugFeature}>
        <Text testID="copyright" variant="subheader" color="neutral">
          {"\u00A9"}
          {currentYear} GigSmart Inc. All Rights Reserved
        </Text>
      </SecretFeatureButton>
      <Stack size="compact" justifyContent="center" alignItems="center">
        <Text testID="config-env" variant="note" color="neutral">
          Environment: {process.env.CONFIG_ENV}
        </Text>

        {isNative ? (
          <>
            <Text testID="native-version" variant="note" color="neutral">
              Native Version: {getVersion()}
            </Text>
            <Text testID="build-number" variant="note" color="neutral">
              Native Build: {getBuildNumber()}
            </Text>
            <Text testID="js-build" variant="note" color="neutral">
              JS Build: {packageBuildNumber} ({isHermes ? "hermes" : "jsc"})
            </Text>
            <Text testID="update-channel" variant="note" color="neutral">
              Update Channel: {Updates.channel ?? "N/A"}
            </Text>
            <Text testID="update-channel" variant="note" color="neutral">
              Expo Runtime: {Updates.runtimeVersion ?? "N/A"}
            </Text>
            <Text testID="update-channel" variant="note" color="neutral">
              Expo Runtime: {Updates.isEnabled}
            </Text>
            {updateLabel && (
              <Text testID="code-push-label" variant="note" color="neutral">
                update id: {updateLabel}
              </Text>
            )}
          </>
        ) : (
          <Text testID="build-number" variant="note" color="neutral">
            {`Build Number: ${packageBuildNumber}`}
          </Text>
        )}

        <Text
          testID="code-push-label"
          variant="note"
          color="link"
          onPress={showOssDisclaimer}
        >
          Open Source Attribution
        </Text>
      </Stack>

      {bundleUpToDate && isNative && (
        <Button
          color="highlight"
          label="Update Bundle"
          eventTargetName="Update Bundle Bubble Button"
          testID="website-button"
          onPress={handleUpdate}
        />
      )}

      {remoteLoggingEnabled() && remoteUrl && (
        <Button
          color="neutral"
          size="small"
          label="Copy Remote Logger URL"
          eventTargetName="Remote Logging Copy Button"
          testID="website-button"
          onPress={() => Clipboard.setString(remoteUrl ?? "")}
        />
      )}
    </Stack>
  );
}
